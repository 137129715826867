@use 'sass:map';
@use '../@tokens' as tokens;
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

// Force hardcoded CSS property values from Spirit Design System

.Button {
  font-weight: map-get(tokens.$body-small-text-bold, 'mobile', 'font-weight');
  font-size: map-get(tokens.$body-small-text-bold, 'mobile', 'font-size');
  line-height: map-get(tokens.$body-small-text-bold, 'mobile', 'line-height');
}

.Button--secondary {
  color: tokens.$action-primary-default;
  background-color: tokens.$action-secondary-default;

  &:hover,
  &:focus {
    color: tokens.$action-primary-hover;
    background-color: tokens.$action-secondary-hover;
  }

  &:active {
    color: tokens.$action-primary-active;
    background-color: tokens.$action-secondary-active;
  }

  &:disabled,
  &.Button--disabled {
    color: tokens.$text-secondary-disabled;
    background-color: tokens.$action-secondary-disabled;
  }

  &.Button--loading {
    color: transparent;
  }

  &.Button--loading > svg {
    color: tokens.$action-primary-default;
  }
}

.Button--tertiary {
  color: tokens.$action-primary-default; // In Figma colour is actually $action-primary-default and not $action-tertiary-default
  background-color: tokens.$background-basic;
  border-color: tokens.$action-tertiary-default;

  &:hover,
  &:focus {
    color: tokens.$action-tertiary-hover;
    background-color: tokens.$background-basic;
    border-color: tokens.$action-tertiary-hover;
  }

  &:active {
    color: tokens.$action-tertiary-active;
    background-color: tokens.$background-basic;
    border-color: tokens.$action-tertiary-active;
  }

  &:disabled,
  &.Button--disabled {
    color: tokens.$action-tertiary-disabled;
    background-color: tokens.$background-basic;
    border-color: tokens.$action-tertiary-disabled;
  }
}