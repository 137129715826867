@use '../@tokens' as tokens;
@use '../tokens/colors' as colors;

/**
 * Modal component and gallery detail navigation (left/right arrows) are secondary buttons
 * but have a few color modifications and are rounded.
 */
@mixin circle-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: tokens.$space-900;
  height: tokens.$space-900;
  padding: 0;
  color: tokens.$action-link-secondary-default;
  background: transparent;
  border: 0;
  border-radius: 50%;

  &:focus,
  &:hover {
    color: tokens.$action-link-secondary-hover;
    background-color: colors.$gray-100;
  }

  &:disabled {
    color: tokens.$action-link-secondary-disabled;
    background: transparent;
  }
}