@use '../@tokens' as tokens;

// Force hardcoded CSS property values from Spirit Design System

.Checkbox__text {
  display: flex;
  margin-left: tokens.$space-400;
  align-items: center;
}

.Checkbox__label {
  font-size: map-get(tokens.$body-medium-text-regular, 'mobile', 'font-size');
}
