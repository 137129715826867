@use '../tokens/colors';
@use '../@tokens' as tokens;
@use "../../node_modules/@lmc-eu/spirit-web/scss/tools/breakpoint";

html {
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: unquote(map-get(tokens.$body-medium-text-regular, 'mobile', 'font-family'));
  font-size: map-get(tokens.$body-medium-text-regular, 'mobile', 'font-size');
  font-style: normal;
  font-weight: map-get(tokens.$body-medium-text-regular, 'mobile', 'font-weight');
  line-height: map-get(tokens.$body-medium-text-regular, 'mobile', 'line-height');
  color: tokens.$text-primary-default;

  &::before {
    content: '';
    position: absolute;
    font-size: 0;
    z-index: -1;
    visibility: hidden;

    @include breakpoint.up(tokens.$breakpoint-mobile) {
      content: 'mobile';
    }

    @include breakpoint.up(tokens.$breakpoint-tablet) {
      content: 'tablet';
    }

    @include breakpoint.up(tokens.$breakpoint-desktop) {
      content: 'desktop';
    }
  }
}

button {
  color: tokens.$text-primary-default;
}

hr {
  border: 0;
  border-bottom: tokens.$border-width-100 tokens.$border-style-100 tokens.$border-primary-default;
}
